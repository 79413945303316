import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import numeral from 'numeral';
import cn from 'classnames';
import NumberFormat from 'react-number-format';
import CreatableSelect from 'react-select/creatable';
import Checkbox from '@material-ui/core/Checkbox';
import Popper from '@material-ui/core/Popper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import DatePicker from "react-datepicker";
import IconButton from 'components/IconButton';
import CommentIcon from 'components/CommentIcon';
import { NUM_FORMAT } from 'constants/config';
import { calculateAverage } from 'utils/num';
import { calculateRowTotal } from 'views/BudgetPlanner/utils'
import { categories } from './categories';
import AddIcon from 'images/icons/add.svg';
import RemoveIcon from 'images/icons/remove.svg';
import ErrorIcon from 'images/icons/error.svg';
import './CashflowTable.scss';

const CUSTOM_SELECT_STYLES = {
    control: (provided, state) => ({
        ...provided,
        width: '275px !important',
        height: '35px',
        minHeight: '35px',
        border: '1px solid #26273F',
        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.05)',
        borderRadius: '5px',
    }),
    singleValue: (provided, state) => ({
        ...provided,
        position: 'relative',
        top: '-2px',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        position: 'relative',
        top: '0',
    }),
    input: (provided, state) => ({
        ...provided,
        position: 'relative',
        top: '-2px',
    }),
};

class CashflowTable extends Component {
    static propTypes = {
        type: PropTypes.string,
        fields: PropTypes.array,
        rows: PropTypes.array,
        removeRow: PropTypes.func,
        addRow: PropTypes.func,
        editRow: PropTypes.func,
        onBlur: PropTypes.func,
        mobile: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            openNote: null,
            notesAnchor: null,
        };
    }


    formatNum = (num) => {
        return (num === '') ? '' : numeral(num).format(NUM_FORMAT);
    }

    toggleNotes = (e, row) => {
        e.stopPropagation();

        this.setState({
            openNote: (this.state.openNote) ? null : row.id,
            notesAnchor: (this.state.notesAnchor) ? null : e.currentTarget.parentNode,
        });
    }

    saveNotes = (e) => {
        this.props.onBlur();
        this.setState({ openNote: null, notesAnchor: null });
    }

    saveCheckbox = (e, field, type, index) => {
        this.props.onBlur(field, e.target.checked, type, index);
    }

    renderField = (field, row, index) => {
        const { tab, type, editRow, onBlur } = this.props;
        const { openNote, notesAnchor } = this.state;

        switch(field.id) {
            case 'source':
            case 'account':
            case 'description':
                if (row.id && field.id !== 'description' && tab === 'update') {
                    return (<label className={field.id}>{row[field.id]}</label>);
                } else if ((tab === 'update' && field.id === 'description') || (tab !== 'update')) {
                    return (
                        <input
                            type="text"
                            name={field.id}
                            placeholder={field.placeholder}
                            value={row[field.id]}
                            onChange={(e) => editRow(e, field, type, index)}
                            onBlur={(onBlur) ? (e) => onBlur(e, index) : null}
                         />
                     );
                } else {
                    return (<label className={field.id}>{row[field.id]}</label>);
                }
            case 'frequency':
                if (row.id && tab === 'update') {
                    return (<label className={field.id}>{row[field.id]}</label>);
                } else {
                    return (
                        <select className={field.id} name={field.id} value={row[field.id]} onChange={(e) => editRow(e, field, type, index)} onBlur={(onBlur) ? (e) => onBlur(e, index) : null}>
                            <option value="0">Select</option>
                            {field.options.map((o, i) => {
                                if (o.group) {
                                    return (
                                        <optgroup label={o.label} key={i}>
                                            {o.options.map(opt => <option value={opt.value} key={opt.name}>{opt.name}</option>)}
                                        </optgroup>
                                    );
                                } else {
                                    return (<option value={o.value} key={i}>{o.name}</option>);
                                }
                            })}
                        </select>
                    );
                }
            case 'category':
                if (type === 'manualTransactions') {
                    return (
                        <>
                            {row.category && row.record && <span className={cn('dot', row.record.type)} />}
                            <Autocomplete
                                className="default-category"
                                options={field.options}
                                groupBy={(option) => option.displayType}
                                getOptionLabel={(option) => option.source || option.category || ''}
                                onChange={(e, value) => {
                                    editRow(e, field, type, index, value);
                                    onBlur(e, value.id, row, type, index, field.id);
                                }}
                                value={(row.category) ? row.record : ''}
                                renderOption={(option) => {
                                    let text = option.source || option.category;
                                    if (option.savings_withdrawal) {
                                        text += ' - Savings Withdrawal';
                                    }
                                    return (
                                        <span className={cn('option', option.type)}>{text}</span>
                                    );
                                }}
                                renderInput={(params) => {
                                    if (row.record && row.record.savings_withdrawal) {
                                        params.inputProps.value += ' - Savings Withdrawal';
                                    }

                                    return (
                                        <div ref={params.InputProps.ref}>
                                            <input type="text" {...params.inputProps} title={params.inputProps.value} placeholder="-- Uncategorized --" />
                                        </div>
                                    );
                                }}
                            />
                        </>
                    );
                } else if (row.id && tab === 'update') {
                    return (<label className={field.id}>{row[field.id]}</label>);
                } else {
                    return (
                        <CreatableSelect
                            value={{ value: row[field.id], label: row[field.id] }}
                            options={categories}
                            styles={CUSTOM_SELECT_STYLES}
                            onChange={(val) => {
                                onBlur(field, val, type, index);
                            }}
                        />
                    );
                }
            case 'amount':
            case 'low':
            case 'high':
                if (row.id && tab === 'update') {
                    if (field.id === 'amount') {
                        return (
                            <NumberFormat
                                className={field.id}
                                displayType="text"
                                value={(row.low || row.high) ? calculateAverage(row.low, row.high) : row[field.id] * row.frequency}
                                thousandSeparator={true}
                                prefix={'$'}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                onBlur={(onBlur) ? (e) => onBlur(e, index, row) : null}
                            />
                        );
                    }
                } else if (row.frequency === '1.0') {
                    if (!row.id && tab === 'update') {
                        return (
                            <NumberFormat
                                className={field.id}
                                displayType="text"
                                value={(row.low || row.high) ? calculateAverage(row.low, row.high) : row[field.id] * row.frequency}
                                thousandSeparator={true}
                                prefix={'$'}
                                decimalScale={2}
                                fixedDecimalScale={true}
                            />
                        );
                    } else {
                        return (
                            <div className="low-high">
                                <span>Typically between</span>
                                <NumberFormat
                                    className="low"
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    prefix={'$'}
                                    placeholder={field.placeholder + ' - Low'}
                                    value={row.low}
                                    onValueChange={(values) => {
                                        let e = { target: {value: values.value} };
                                        editRow(e, {id: 'low'}, type, index);
                                    }}
                                    onBlur={(onBlur) ? (e) => onBlur(e, index) : null}
                                />
                                <span>and</span>
                                <NumberFormat
                                    className="high"
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    prefix={'$'}
                                    placeholder={field.placeholder + ' - High'}
                                    value={row.high}
                                    onValueChange={(values) => {
                                        let e = { target: {value: values.value} };
                                        editRow(e, {id: 'high'}, type, index);
                                    }}
                                    onBlur={(onBlur) ? (e) => onBlur(e, index) : null}
                                />
                            </div>
                        );
                    }
                } else {
                    const blurFunc = (typeof(onBlur) === 'function') ? onBlur : (function() {});
                    return (
                        <NumberFormat
                            className={field.id}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            prefix={'$'}
                            placeholder={field.placeholder}
                            value={row[field.id]}
                            onValueChange={(values) => {
                                let e = { target: {value: values.value} };
                                editRow(e, field, type, index);
                            }}
                            onBlur={(e) => blurFunc(e, index, row)}
                        />
                    );
                }
                break;
            case 'difference':
                let amount = (row.low || row.high) ? calculateAverage(row.low, row.high) : (row.amount || 0) * (row.frequency || 1);
                let diff = (row.actual) ? row.actual - amount : 0 - amount;
                let classes = cn(field.id, diff < 0 && 'spending', diff >= 0 && 'income')
                if (type === 'spending') {
                    classes = cn(field.id, diff <= 0 && 'income', diff > 0 && 'spending')
                }
                return (<label className={classes}>{this.formatNum(diff)}</label>);
            case 'actual':
                return (
                    <NumberFormat
                        className={field.id}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        prefix={'$'}
                        value={row[field.id]}
                        placeholder={field.placeholder}
                        onValueChange={(values) => {
                            let e = { target: {value: values.value} };
                            editRow(e, field, type, index);
                        }}
                    />
                );
            case 'average':
                let average = calculateRowTotal(row);
                return (<label className={field.id}>{this.formatNum(Math.abs(average))}</label>);
            case 'notes':
                return (
                    <div className="notes">
                        <CommentIcon on={row.notes !== ''} onClick={(e) => this.toggleNotes(e, row)} />
                        <Popper id={row.id} open={openNote === row.id} anchorEl={notesAnchor} disablePortal={true}>
                            <ClickAwayListener onClickAway={this.saveNotes}>
                                <div className="note">
                                    <textarea placeholder="Add your note here." onChange={(e) => editRow(e, field, type, index)} value={row.notes} />
                                </div>
                            </ClickAwayListener>
                        </Popper>
                    </div>
                );
            case 'savings_withdrawal':
                return (
                    <div className="savings-withdrawal">
                        <Checkbox id={row.name} checked={row.savings_withdrawal} onChange={(e) => this.saveCheckbox(e, field, type, index)} />
                        {row.savings_withdrawal && <span>A savings withdrawal is the total amount of an item
                            funded with previously-saved dollars instead of regular monthly
                            income. <a href="https://moneyswell.com" target="_blank" rel="noreferrer">Learn more</a>.</span>}
                    </div>
                );
            case 'date':
                let value = row.date;
                if (typeof(value) === 'string' && value === '') value = null;
                if (typeof(value) === 'string' && value !== '') value = moment(value).toDate();

                return (
                    <DatePicker className="datepicker"
                        selected={value}
                        dateFormat="MM/dd/yyyy"
                        minDate={field.min} maxDate={field.max}
                        onChange={(date) => {
                            let e = { target: {value: date} };
                            editRow(e, field, type, index);
                            onBlur(e, date, row, null, null, field.id);
                        }}
                    />
                );
            case 'name':
                return (
                    <input
                        type="text"
                        name={field.id}
                        placeholder={field.placeholder}
                        value={row[field.id]}
                        onChange={(e) => editRow(e, field, type, index)}
                        onBlur={(e) => onBlur(e, row[field.id], row, null, null, field.id)}
                     />
                 );
            default:
                break;
        }
    }

    render() {
        let self = this;
        const { classes, type, tab, fields, rows, removeRow, addRow, editRow, mobile } = this.props;

        if (mobile) {
            let body = null;
            if (fields?.length && rows?.length) {
                body = rows.map((r, i, arr) => {
                    let goalValue = (r.low || r.high) ? calculateAverage(r.low, r.high) : r.amount * r.frequency;

                    if ((i === arr.length - 1 && !r.id) || r.editing) {
                        if (tab === 'update') {
                            let actual = fields[fields.length - 1];

                            return (
                                <div className="row" key={i}>
                                    <div className="update-label">{r.source || r.account || r.category}</div>
                                    <div className="update-label">
                                        <NumberFormat
                                            displayType="text"
                                            value={goalValue}
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                        />
                                    </div>
                                    <div className={'field actual'}>
                                        <label>{actual.label}</label>
                                        {self.renderField(actual, r, i)}
                                    </div>
                                </div>
                            );
                        } else {
                            return (
                                <div className={cn('row', tab !== 'plan' && tab !== 'update' && 'rp')} key={i}>
                                    {tab !== 'plan' && tab !== 'update' &&
                                        <div className="action rp">
                                            <img src={RemoveIcon} alt="Remove icon, negative sign in circle, green" onClick={() => removeRow(type, i)} />
                                        </div>
                                    }
                                    {fields.map((f, j) => {
                                        return (
                                            <div className={'field ' + f.id} key={j}>
                                                <label>{f.label}</label>
                                                {self.renderField(f, r, i)}
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        }
                    } else {
                        let value = null;
                        if (tab === 'update' && r.actual) value = r.actual;
                        else if (tab === 'update' && !r.actual) value = 0;
                        else if (r.amount) value = r.amount;
                        else if (r.low || r.high) value = calculateAverage(r.low, r.high);
                        else value = r.amount * r.frequency;

                        return (
                            <div className="row collapsed" key={i} onClick={(e) => editRow(e, null, type, i)}>
                                <div className="row-content">
                                    <label>{r.source || r.account || r.category}</label>
                                    <span>
                                        <NumberFormat
                                            displayType="text"
                                            value={value}
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                        />
                                    </span>
                                    {type !== 'spending' && value < goalValue && <img src={ErrorIcon} alt="Error icon, red, exclamation mark" />}
                                    {type === 'spending' && value > goalValue && <img src={ErrorIcon} alt="Error icon, red, exclamation mark" />}
                                </div>
                            </div>
                        );
                    }
                });
            }

            return (
                <div id={type+'Table'} className={cn('cashflow-table', classes)}>
                    {body}
                    {tab !== 'update' &&
                        <div className={cn('add-row', tab !== 'plan' && 'expanded')}>
                            {tab !== 'plan' && <label onClick={(e) => addRow(e, type)}>{`Add ${type[0].toUpperCase() + type.slice(1)}`}</label>}
                            <img src={AddIcon} alt="Add icon, plus sign in circle, green" onClick={(e) => addRow(e, type)} />
                        </div>
                    }
                </div>
            );
        } else {
            let header = null;
            if (fields && fields.length) {
                header = fields.map((f, i) => {
                    return (
                        <div className={'column ' + f.id} key={i}>
                            {f.label}
                            {f.tooltip && <IconButton type="info" content="?" tooltip={f.tooltip} />}
                            {f.sub && <span className="sub">{f.sub}</span>}
                        </div>
                    );
                });
            }

            let body = null;
            if (rows && rows.length) {
                body = rows.map((r, i) => {
                    return (
                        <div className="row" key={i}>
                            <div className="action">
                                <img src={RemoveIcon} alt="Remove icon, negative sign in circle, green" onClick={() => removeRow(type, i)} />
                            </div>
                            {fields.map((f, j) => {
                                return (
                                    <div className={cn('field ' + f.id, r.frequency === '1.0' && 'lh')} key={j}>
                                        {self.renderField(f, r, i)}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })
            }

            let addType = type[0].toUpperCase() + type.slice(1);
            if (type === 'spending') {
                addType = 'Spending Item';
            } else if (type === 'manualTransactions') {
                addType = 'Manual Transaction';
            }

            return (
                <div id={type+'Table'} className={cn('cashflow-table', classes)}>
                    <div className="header">{header}</div>
                    <div className="body">{body}</div>
                    {tab !== 'update' &&
                        <div className="add-row" onClick={(e) => addRow(e, type)}>
                            <label onClick={(e) => addRow(e, type)}>{`Add ${addType}`}</label>
                            <img src={AddIcon} alt="Add icon, plus sign in circle, green" onClick={(e) => addRow(e, type)} />
                        </div>
                    }
                </div>
            );
        }
    }
}

export default CashflowTable;
